<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :width="computerWidth"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }" v-if="false">
        <v-btn color="secondary" v-bind="attrs" v-on="on" v-show="false">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="AboutDialogWrapper">
        <v-toolbar dark color="rgb(20, 96, 150)" elevation="1">
          <v-toolbar color="#acd265" elevation="1" v-if="false"></v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>Newsletter Sign Up</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="contactTextBody">
          <div id="mc_embed_shell">
            <div id="mc_embed_signup">
              <form
                action="https://onemap.us6.list-manage.com/subscribe/post?u=bea8d97bf5c49cde24738b632&amp;id=12906dc3c5&amp;f_id=008710e2f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_self"
                novalidate=""
              >
                <div id="mc_embed_signup_scroll">
                  <h2>Sign Up for Updates</h2>
                  <div class="indicates-required">
                    <span class="asterisk">*</span> indicates required
                  </div>
                  <div class="mc-field-group">
                    <label for="mce-EMAIL"
                      >Email Address <span class="asterisk">*</span></label
                    ><input
                      type="email"
                      name="EMAIL"
                      class="required email"
                      id="mce-EMAIL"
                      required=""
                      value=""
                    />
                  </div>
                  <div class="mc-field-group canHide">
                    <label for="mce-FNAME">First Name </label
                    ><input
                      type="text"
                      name="FNAME"
                      class=" text"
                      id="mce-FNAME"
                      value=""
                    />
                  </div>
                  <div class="mc-field-group canHide">
                    <label for="mce-LNAME">Last Name </label
                    ><input
                      type="text"
                      name="LNAME"
                      class=" text"
                      id="mce-LNAME"
                      value=""
                    />
                  </div>
                  <div class="mc-field-group">
                    <label for="mce-ORG">Organisation </label
                    ><input
                      type="text"
                      name="ORG"
                      class=" text"
                      id="mce-ORG"
                      value=""
                    />
                  </div>
                  <div hidden="">
                    <input type="hidden" name="tags" value="4057286" />
                  </div>
                  <div id="mce-responses" class="clear">
                    <div
                      class="response"
                      id="mce-error-response"
                      style="display: none;"
                    ></div>
                    <div
                      class="response"
                      id="mce-success-response"
                      style="display: none;"
                    ></div>
                  </div>
                  <div
                    aria-hidden="true"
                    style="position: absolute; left: -5000px;"
                  >
                    <input
                      type="text"
                      name="b_bea8d97bf5c49cde24738b632_12906dc3c5"
                      tabindex="-1"
                      value=""
                    />
                  </div>
                  <center>
                    <div class="clear">
                      <input
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                        value="Subscribe"
                      />
                    </div>
                  </center>

                  <div class="indicates-required">
                    I understand by submitting my enquiry I am agreeing to the
                    terms set out in the
                    <a
                      href="https://tract.com.au/privacy-policy/"
                      target="_blank"
                      >Privacy Policy</a
                    >.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "MailChimpSignUp",

  components: {},

  data() {
    return {
      computerWidth: "550px",
      dialog: false
    };
  },

  created() {
    eventBus.$on("loadMailChimpSignUpDialog", () => {
      this.dialog = true;

      window.mvAnalyticEvent("dialogOpen_MailChimpSignUp");
    });
  },

  mounted() {
    let firstVisitToday = false;

    const lastVisit = localStorage.getItem("lastVisitDate");
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    if (lastVisit !== today) {
      firstVisitToday = true;
      // Update the last visit date
    }
    localStorage.setItem("lastVisitDate", today);
    setTimeout(() => {
      if (firstVisitToday) {
        this.dialog = true;
      }
    }, 1500);
  }
};
</script>

<style>
/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  margin: 20px;
}
#mc_embed_signup h2 {
  font-weight: bold;
  padding: 0;
  margin: 15px 0;
  font-size: 1.4em;
}
#mc_embed_signup input {
  border: 1px solid #abb0b2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
#mc_embed_signup input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
#mc_embed_signup input[type="radio"] {
  -webkit-appearance: radio;
}
#mc_embed_signup input:focus {
  border-color: #333;
}
#mc_embed_signup .button {
  clear: both;
  background-color: #111;
  border: 0 none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: fit-content;
  width: -moz-fit-content;
}
#mc_embed_signup .button:hover {
  background-color: #222;
}
#mc_embed_signup .small-meta {
  font-size: 11px;
}
#mc_embed_signup .nowrap {
  white-space: nowrap;
}

#mc_embed_signup .mc-field-group {
  clear: left;
  position: relative;
  width: 96%;
  padding-bottom: 3%;
  min-height: 50px;
  display: grid;
}
#mc_embed_signup .size1of2 {
  clear: none;
  float: left;
  display: inline-block;
  width: 46%;
}
* html #mc_embed_signup .size1of2 {
  margin-right: 2%; /* Fix for IE6 double margins. */
}
#mc_embed_signup .mc-field-group label {
  display: block;
  margin-bottom: 3px;
}
#mc_embed_signup .mc-field-group input {
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
}
#mc_embed_signup .mc-field-group select {
  display: inline-block;
  width: 99%;
  padding: 5px 0;
  margin-bottom: 2px;
}
#mc_embed_signup .mc-address-fields-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 96%;
  gap: 15px;
}
#mc_embed_signup .mc-sms-phone-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 96%;
  gap: 15px;
  padding-top: 5px;
}

#mc_embed_signup .datefield,
#mc_embed_signup .phonefield-us {
  padding: 5px 0;
}
#mc_embed_signup .datefield input,
#mc_embed_signup .phonefield-us input {
  display: inline;
  width: 60px;
  margin: 0 2px;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px 0 2px 0;
}
#mc_embed_signup .phonefield-us .phonearea input,
#mc_embed_signup .phonefield-us .phonedetail1 input {
  width: 40px;
}
#mc_embed_signup .datefield .monthfield input,
#mc_embed_signup .datefield .dayfield input {
  width: 30px;
}
#mc_embed_signup .datefield label,
#mc_embed_signup .phonefield-us label {
  display: none;
}

#mc_embed_signup .indicates-required {
  text-align: right;
  font-size: 11px;
}
#mc_embed_signup .asterisk {
  color: #e85c41;
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
}
#mc_embed_signup .clear {
  clear: both;
}
#mc_embed_signup .foot {
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 96%;
  align-items: center;
}
@media screen and (max-width: 400px) {
  #mc_embed_signup .foot {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  #mc_embed_signup .referralBadge {
    width: 50%;
  }
}

#mc_embed_signup .brandingLogo {
  justify-self: right;
}
@media screen and (max-width: 400px) {
  #mc_embed_signup .brandingLogo {
    justify-self: left;
  }
}

#mc_embed_signup .mc-field-group.input-group ul {
  margin: 0;
  padding: 5px 0;
  list-style: none;
}
#mc_embed_signup .mc-field-group.input-group ul li {
  display: block;
  padding: 3px 0;
  margin: 0;
}
#mc_embed_signup .mc-field-group.input-group label {
  display: inline;
}
#mc_embed_signup .mc-field-group.input-group input {
  display: inline;
  width: auto;
  border: none;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}
#mc_embed_signup .helper_text {
  color: #8d8985;
  margin-top: 2px;
  display: inline-block;
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
}

#mc-embedded-subscribe {
  clear: both;
  width: auto;
  display: block;
  margin: 1em 0 1em 5%;
}
#mc_embed_signup #num-subscribers {
  font-size: 1.1em;
}
#mc_embed_signup #num-subscribers span {
  padding: 0.5em;
  border: 1px solid #ccc;
  margin-right: 0.5em;
  font-weight: bold;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  display: inline-block;
  margin: 2px 0 1em 0;
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  color: #e85c41;
}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
  border: 2px solid #e85c41;
}

.AboutDialogWrapper .contactTextBody #mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 600px;
  max-width: 100%;
}

.AboutDialogWrapper a {
  text-decoration: underline !important;
}
.AboutDialogWrapper .contactTextBody {
  height: auto;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

.AboutDialogWrapper ul.contact {
  padding-left: 0;
}

.AboutDialogWrapper ul.contact li {
  padding-bottom: 10px;
}

@media screen and (max-width: 400px) {
  #mc_embed_signup > form > div {
    scale: 0.75;
  }
}

x.canHide {
  display: none !important;
}
</style>

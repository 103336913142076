<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 750"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="linkButton" v-show="false">
          Acknowledgement of Country
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-card-title
          :style="{
            background: 'rgb(20 96 150)'
          }"
          class="  xlighten-2 white--text text-center justify-center siteHeadingText"
        >
          Acknowledgement of Country
        </v-card-title>
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="6"
            style="height: 291.5px;"
          >
            <div
              style="    background-color: rgb(62, 45, 41);height: 100%;width: 100%;"
            >
              <v-img
                class="ma-0"
                style="width: 100%;"
                alt="Our Country by Alfred Carter"
                src="/assets/Alfred-Carter-Our-Country-2022.jpg"
              >
                <font
                  class="pl-2"
                  style="
                    font-size: 13px;
                    color: white;
                    position: absolute;
                    bottom: 0;
                    background: #8080808c;  width:100%"
                >
                  Our Country, 2022, Original artwork by <br />
                  Alfred Carter, Gunaikurnai
                </font>
              </v-img>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="contentWrapper">
              <v-card-text class="mt-0 pb-0  AoCText">
                <center>
                  We pay our respects to the Traditional Custodians of Country
                  including the Wurundjeri woi-wurrung, the Bunurong and the
                  Boon Wurrung, recognising their rich heritage and connection
                  to Country and acknowledging the ongoing sovereignty of all
                  Aboriginal and Torres Strait Islander Nations.
                </center>
              </v-card-text>
              <v-card-text v-if="$vuetify.breakpoint.smAndDown">
                <center>
                  <img
                    class="mt-2"
                    style="max-width: 70%;width:300px;"
                    alt="Our Country by Alfred Carter"
                    src="/assets/Alfred-Carter-Our-Country-2022.jpg"
                  />

                  <div
                    class="pl-2"
                    style="
                    font-size: 13px;"
                  >
                    Our Country, 2022, Original artwork by Alfred Carter,
                    Gunaikurnai
                  </div>
                </center>
              </v-card-text>
            </div>
            <v-card-actions class="AoKAction">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="rgb(169 26 68)"
                class="white--text"
                @click="openIntro = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "./../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      openIntro: false
    };
  },
  computed: {
    ...mapState({
      //appSettings: state => state.global.appSettings
    })
  },
  methods: {},
  created() {
    eventBus.$on("loadAcknowlegementDialog", () => {
      window.mvAnalyticEvent("dialogOpen_Acknowlegement");

      this.openIntro = true;
    });
  },
  mounted() {
    setTimeout(() => {
      //this.openIntro = true;
    }, 500);
  }
};
</script>

<style>
.aboutText,
.aboutText .v-card__text,
.AoCText,
.WelcomeText,
.contactTextBody,
.contactTextBody .v-card__text {
  font-size: 16px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75) !important;
}
@media only screen and (max-width: 550px) {
  .aboutText,
  .aboutText .v-card__text,
  .AoCText,
  .WelcomeText {
    font-size: 14px !important;
  }
}

.aboutText .v-card__text.sources {
  font-size: 12px !important;
}

.isTabletOrComputer .AoKAction {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
